import { MarketButton } from '@market/react';
import type {
  GetServerSidePropsContext,
  GetStaticPropsResult,
  InferGetServerSidePropsType,
} from 'next';
import NoFooterLayout from 'components/no-footer-layout';
import {
  ValidRegion,
  MENU_URL,
  LOGIN_REDIRECT_TO_CASH_BACK_URL,
  REGION_NOT_AVAILABLE_TYPE,
  REGION_NOT_AVAILABLE,
  BUYER_ID_HEADER_NAME,
} from 'lib/constants';
import { formatRegionQueryParam } from 'lib/formatters';

import styles from './index.module.css';
import { ReactFragment } from 'react';
import { useAccount } from 'hooks/use-account';
import LoadingIndicator from 'components/loading-indicator';
import { setRegionCookie } from 'lib/cookie';
import Link from 'next/link';

export type IndexPageProps = {
  regionFromQueryParam: ValidRegion | REGION_NOT_AVAILABLE_TYPE;
  hasSessionCookie: boolean;
};

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetStaticPropsResult<IndexPageProps>> {
  const buyerId = context.req.headers[BUYER_ID_HEADER_NAME];
  const selectedRegion = context.query.region;
  const regionFromQueryParam =
    formatRegionQueryParam(selectedRegion) || REGION_NOT_AVAILABLE;

  return {
    props: {
      regionFromQueryParam,
      hasSessionCookie: Boolean(buyerId),
    },
  };
}

const Home = ({
  hasSessionCookie,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { isLoading: isLoadingAccount, account } = useAccount({
    hasSessionCookie,
  });

  if (!isLoadingAccount) {
    if (account?.status === 'ACTIVE') {
      if (account?.region) {
        setRegionCookie(account.region);
      }
    }
  }

  return (
      <div market-features="typography" suppressHydrationWarning={true}>
      {
        isLoadingAccount ? ( <LoadingIndicator /> ) : (
          <main className={styles.content}>
            <img
              src="/images/sign.png"
              width={136}
              height={109}
              alt="Closed Sign"
            />
            <h1 className="display-10">Square Local Offers<br/> is closed for business</h1>
            <p>
              You can continue to redeem your remaining cash back balance through <b>December 26, 2024</b>. We apologize for any inconvenience and appreciate your understanding. Exciting new features are on the way—we hope to see you back soon!
            </p>
            <Link
              data-testid="redeem-button-link"
              href={account ? MENU_URL.cashback : LOGIN_REDIRECT_TO_CASH_BACK_URL}
            >
              <MarketButton
                className={styles.cashOutButton}
                rank="primary"
                size="medium"
              >
                <span>Redeem</span>
              </MarketButton>
            </Link>
          </main>
        )
    }
  </div>
  );

};

Home.getLayout = function getLayout(page: ReactFragment) {
  return <NoFooterLayout hideMenu={true}>{page}</NoFooterLayout>;
};

export default Home;
