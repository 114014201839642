import { ReactNode } from 'react';
import LocalOffersHeader from './local-offers-header';
import styles from './no-footer-layout.module.css';

type NoFooterLayoutProps = {
  children: ReactNode;
  hideMenu?: boolean;
};

export default function NoFooterLayout({
  children,
  hideMenu,
}: NoFooterLayoutProps) {
  return (
    <>
      <LocalOffersHeader hideMenu={hideMenu} />
      <main className={styles.main}>{children}</main>
    </>
  );
}
